.list-group {
	box-shadow: none;
	> .list-group-item {
        border: none;
        border-radius: 0;
        background: none;
        padding: 10px 5px 10px 5px;
        border-top: 1px solid rgba(0,0,0,0.1);
        border-bottom: 1px solid rgba(0,0,0,0.1);
		>.btn {
            padding: 5px 10px;
            font-size: 12px;
            line-height: 1.5;
            border-radius: 3px;
            margin-left: 5px;
            float: right;
            .transition();
		}
        > .badge {
            padding: 5px;
            margin-top: 4px; 
            min-width: 30px;
            .transition();
        }
		.transition();
		&:hover, 
		&:focus {
			background: @color-secondary;
			border-top: 1px solid @color-main;
			border-bottom: 1px solid @color-main;
            > .badge {
                background: @color-main;
                color: #fff;
            }
		}
		&:last-child {
			margin-bottom: 0;
			.rounded( 0 );
		}
		&.active, 
		&.active:hover, 
		&.active:focus {
			background: none;
			padding: 5px 0px;
			text-shadow: none;
			.text-shadow( none );
			color: @color-main;
			font-weight: bold;
			text-transform: uppercase;			
			border-bottom: 3px solid @color-secondary;
		}
		&.list-group-item-info {
			color: #fff;
			background: @color-main;		
			border-bottom: 1px solid @color-main;
		}
	} 
	&.list-group-sm {
		> .list-group-item {
            padding: 5px 0px 5px 5px;
            font-size: @font-size-small;
			>.btn {
                padding: 0px 5px;
                font-size: @font-size-small;
                margin: -1px 0px 0px 0px;
                .transition();
			}
			>.badge {
                border-radius: 0;
                margin: -5px 0 0 0;
                padding: 7px 0 8px 0;
                color: #333;
                background: #e6e6e6;
                min-width: 45px;
			}
			&:hover, 
		    &:focus,
		    &.active, 
			&.active:hover, 
			&.active:focus {
    			>.badge {
                    background: @color-main;
                    color: #fff;
    			}
			}
		}
	}
}	

.alert{
	border-radius: 0;
	&.alert-small {
		font-size: 90%;
	}
	&.alert-default {
        background-color: #f5fafe;
        border-color: #dcf0ff;
        color: #006ebe;
	}
    .alert-warning {
        background-color: #fffbd8;
        border-color: #fff2d9;
        color: #64450d;
    }
}
.responsive-table {
    position: relative;
    >.mask-loading {
        position: absolute;
        top: 44px;
        left: 0;
        right: 0;
        bottom: 50px;
        padding: 50px;
        font-size: 100px;
        color: @color-main;
        text-shadow: 1px 1px 1px #fff, -1px -1px 1px #fff, -1px 1px 1px #fff, 1px -1px 1px #fff;
        background: url("@{host-images}/common/pattern.png") rgba(0, 0, 0, 0.01);
        text-align: center;
        display:none;
    }
    .responsive-table-length {
        
    }
    .responsive-table-filter {
        
    }
    >table {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .responsive-table-result {
        margin-bottom: 10px;
        padding: 0px 10px
        
    }
    .responsive-table-pagination {
        text-align: right;
        margin-bottom: 10px;
        padding: 0px 10px;
        >ul {
            margin: 0;
        }
        
    }
}
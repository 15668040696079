.tab-content {
    margin-top: 10px;
    padding: 20px;
    background: #fff;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    -moz-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}	

.nav-tabs {
    border: none;
    background: @color-secondary;
    > li {
        margin: 0;
        > a {
            -webkit-box-shadow: none;
            box-shadow: none;
            border: none;
            color: @color-text;
            border-radius: 0;
            margin-right: 0;
            >sup {
                display: block;
                top: 0px;
                color: #fff;
                background: @color-main;
                float: right;
                padding: 0px 5px;
                margin-left: 5px;
                line-height: 12px;
            }
            &:hover,
            &:focus {
                color: @color-main;
                background: #fff;
                border: none;
            }
        }
        &.active {
            > a {
                color: @color-main;
                background: #fff;
                border: none;
                &:hover,
                &:focus {
                    color: @color-main;
                    background: #fff;
                    border: none;
                }
            }
        }
    }
}
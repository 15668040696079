.pagination {
	> li {
		> a, 
		> span{
			color: @color-main;
			background-color: @color-white;
			border: 1px solid @color-gray-30;
			padding: 0px 8px;
			&:hover, 
			&:focus{
				color: @color-white;
				background-color: @color-main;
			}
		}
	}
	> .active {
		> a, 
		> span, 
		> a:hover, 
		> span:hover, 
		> a:focus, 
		> span:focus{
			color: @color-white;
			background-color: @color-main;
			border-color: @color-main;
		}
	}
}
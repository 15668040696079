.table {
    background: #fff;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.2);
	border-bottom: 3px solid @color-main;
	> thead {
		> tr {
			> th {
                background: @color-main;
                border-color: rgba(0, 0, 0, 0.2);
                border-bottom-width: 1px;
                color: #fff;
                text-transform: uppercase;
				&.top { vertical-align:top; }
				&.middle { vertical-align: middle; }
				&.bottom { vertical-align:bottom; }
				&.left { text-align:left; }
				&.center { text-align:center; }
				&.right { text-align:right; }
			}	
		}	
	}	
	> tbody {
		> tr {
			background-color: #fff;
			> td {
				padding: 0px;
				line-height: 18px;
				> div:not(.btn-group) {
			        padding: 1px 2px;
				}
				>.btn,
				>.btn-group >.btn {
                    display: block;
                    width: 100%;
                    border: none;
                    padding: 1px 2px;
                    background: transparent;
                    &:hover,
                    &:focus {
                        color: @color-main ;
                    }
                    &.disabled {
                        &:hover,
                        &:focus {
                            color: @color-red ;
                        } 
                    }
				}
				>.btn-group {
                    padding: 0;
                    width: 100%;
                    border: none;
				}
				.top { vertical-align:top; }
				.middle { vertical-align: middle; }
				.bottom { vertical-align:bottom; }
			    .left { text-align:left; }
				.center { text-align:center; }
				.right { text-align:right; }
				.page-header {
			        margin: 0 0 5px 0;
				    >h1, >h2, >h3, >h4, >h5 {
				        font-size: medium;
				        padding: 0 0 5px 0;
			        }
				}
				.label {
                    display: inline-block;
                    width: 100%;
				}
			}	
			.transition();
			&.odd {
				background-color: #f4f4f4;
			}
			&:hover {
				background: @color-secondary;
			}	
		}	
	}	
}	
/*///////////////////////////////////////////////////////////////////////////
//	DEBUT DE LA PROGRAMMATION ///////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////*/

.badge{
	&.badge-success {
        background-color: #3c763d;
	}
	&.badge-warning {
        background-color: #fcf8e3;
        color: #333;
        border: 1px solid rgba(0,0,0,0.2);
	}
	&.badge-danger {
        background-color: #a94442;
	}
	&.badge-info {
        background-color: #5bc0de;
	}
}

/*///////////////////////////////////////////////////////////////////////////
//	FIN DE LA PROGRAMMATION
///////////////////////////////////////////////////////////////////////////*/
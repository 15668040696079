#bootstrap-media-lightbox-caption-container {
    position: absolute;
    bottom: 10px;
    padding: 0 20px;
    width: 100%
}

#bootstrap-media-lightbox-caption {
    background: none repeat scroll 0% 0% #000;
    color: white;
    padding: 7px 15px;
    border-radius: 30px;
    width: 100%
}

#bootstrap-media-lightbox-backward {
    position: absolute;
    top: 45%;
    left: 20px;
    font-size: 300%;
    color: white;
    cursor: pointer;
}

#bootstrap-media-lightbox-forward {
    position: absolute;
    top: 45%;
    right: 20px;
    font-size: 300%;
    color: white;
    cursor: pointer;
}

#bootstrap-media-lightbox-close {
    position:absolute;
    top:10px;
    right:20px;
    cursor: pointer;
}

#bootstrap-media-lightbox-close .glyphicon-remove-circle {
    color:white;
    font-size: 180%;
}

#bootstrap-media-lightbox-close .glyphicon-remove-circle {
    color:white;
    font-size: 180%;
}

#bootstrap-media-lightbox-close .fa-stack {
    font-size: 180%;
}
#bootstrap-media-lightbox-close .fa-square {
    color:#696969;
}


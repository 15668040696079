.nav-tabs {
    border: none;
    background: @color-secondary;
    > li {
        margin: 0;
        > a {
            -webkit-box-shadow: none;
            box-shadow: none;
            border: none;
            color: @color-text;
            border-radius: 0;
            margin-right: 0;
            &:hover,
            &:focus {
                color: @color-main;
                background: #fff;
                border: none;
            }
        }
        &.active {
            > a {
                color: @color-main;
                background: #fff;
                border: none;
                &:hover,
                &:focus {
                    color: @color-main;
                    background: #fff;
                    border: none;
                }
            }
        }
    }
}
.tab-content {
    margin: 0;
    padding: 20px 0;
}

.block-nav {
    width: 100%;
    max-width: 100%;
    min-height: 100%;
    display: table;
    >div {
        display: table-row;
        min-height: 100%;
        width: 100%;
        max-width: 100%;
        >.nav-stacked {
            border: none;
            background: @color-main;
            width: 320px;
            min-width: 320px;
            display: table-cell;
            vertical-align: top;
            .transition();
            >li {
                margin: 0;
                >a {
                    border: none;
                    color: #fff;
                    border-radius: 0;
                    margin-right: 0;
                    font-size: @font-size-large;
                    background: rgba(230, 240, 250, 0);
                    >i {
                        float: right;
                        font-size: @font-size-xxx-large;
					    color: rgba(238, 238, 238, 0.5);
					    //transform: rotate(180deg);
                        .transition();
                    }
                    .transition();
                    &:hover,
                    &:focus {
                        color: @color-main;
                        background: rgb(230, 240, 250);
                        >i {
    					    color: rgba(0, 110, 190, 0.5);
                        }
                    }
                }
                &.active {
                    >a,
                    >a:hover,
                    >a:focus {
                        color: @color-main;
                        background: #fff;
                        >i {
                            color: @color-main;
                            //transform: rotate(0deg);
                        }
                    }
                }
                &.disabled {
                    >a,
                    >a:hover,
                    >a:focus {
					    color: rgba(255, 255, 255, 0.5);
					    font-style: italic;
					    font-weight: 100;
					    background: none;
                    }
                }
                &.block-treeview {
                    >.container-treeview {
                        padding: 10px;
                        ul {
                            >li {
                                >a {
									color: #fff;
                                    &.jstree-clicked {
                                        background: @color-main !important;
                                        color: #fff !important;
                                    }
                                    &.jstree-hovered {
                                        background: #fff;
                                        color: @color-main;
                                    }
                                }
                            }
                        }
                    }
                }
                &.block-image {
                    background: #0263a2;
                    border-bottom: 4px solid #0073be;
                    >img {
                        width: 50%;
                        display: block;
                        margin: 0 auto;
                        padding: 10px;
                    }  
                }
                &.btn-minimize {
                    display: none;
                	>a {
					    font-size: @font-size-medium;
					    background: #e5e5e5;
					    padding: 5px 15px;
					    color: #e5e5e5;
					    height: 30px;
					    cursor: pointer;
					    >i {
					        float: left;
						    color: @color-main;
						    transform: rotate(0deg);
						    font-size: @font-size-x-large;
					    }
	                    &:hover,
	                    &:focus {
	                    	background: #cccccc;
						    >i {
							    color: #000;
						    }
	                    }
                	}
                }
                >.thumbnail {
					padding: 0;
					border-radius: 0;
					border: none;
					background-color: #eee;
					>img {
						width: 100%;
						padding: 20px;
					}
                }
            }
            
        }
        >.tab-content {
            margin: 0 0 0 240px;
            padding: 0 15px;
            background: #fff;
            -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
            -moz-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
            display: table-cell;
            vertical-align: top;
            min-width: 320px;
            >.tab-pane {
            	&.fade {
			        -webkit-filter: grayscale(100%);
			        -moz-filter: grayscale(100%);
			        -ms-filter: grayscale(100%);
			        -o-filter: grayscale(100%);
			        filter: grayscale(100%);
            		.transition();
            		&.in {
				        -webkit-filter: grayscale(0%);
				        -moz-filter: grayscale(0%);
				        -ms-filter: grayscale(0%);
				        -o-filter: grayscale(0%);
				        filter: grayscale(0%);
            		}
            	}
            }
            >.breadcrumb {
				padding: 13px 15px 12px 15px;
				margin: 0px -15px 20px -15px;
				list-style: none;
				background-color: #f5f5f5;
				border-radius: 0;
            }
        }
        /*
        &.minimize {
	        >.nav-stacked {
			    width: 50px;
			    min-width: 50px;
	            >li {
	                >a {
	                	height: 45px;
	                	>span {
	                		display: none;	
	                	}
	                    >i {
	                    	color: #fff;
	                    	transform: rotate(0deg);
	                    }
	                    &:hover,
	                    &:focus {
	                    	color: @color-main;
		                    >i {
		                    	color: @color-main;
		                    }
	                    }
	                }
	                &.active {
	                    >a,
	                    >a:hover,
	                    >a:focus {
	                        color: @color-main;
	                        background: #fff;
	                        >i {
	                            color: @color-main;
	                            transform: rotate(0deg);
	                        }
	                    }
	                }
	                &.disabled {
	                    >a,
	                    >a:hover,
	                    >a:focus {
	                    	background: none;
	                    	>i {
							    color: rgba(255, 255, 255, 0.5);
	                    	}
	                    }
	                }
	                &.block-treeview {
	                    display: none;
	                }
	                &.btn-minimize {
	                	>a {
						    font-size: @font-size-medium;
							padding: 10px 15px;
						    >i {
								color: #000;
								font-size: @font-size-xxx-large;
								transform: rotate(180deg);
						    }
	                	}
	                }
	                >.thumbnail {
	                	margin-bottom: 0;
	                	>img {
	                		padding: 10px;	
	                	}
						>.input-group {
							display: none;	
						}
	                }
	            }
	        }
        }
        */
    }
}
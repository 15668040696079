@host: "https://cms.iminence.com";
@host-images: "@{host}/public/img/common";	

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i&display=swap');
@font-family: 'Open Sans', sans-serif;
@font-familyCondensed: 'Open Sans', sans-serif;

@font-size-medium: 1.2rem ;
@font-size-large: (@font-size-medium * 1.25);
@font-size-x-large: (@font-size-medium * 1.40);
@font-size-xx-large: (@font-size-medium * 1.55);
@font-size-xxx-large: (@font-size-medium * 1.70);
@font-size-small: (@font-size-medium * 0.85);
@font-size-x-small: (@font-size-medium * 0.70);
@font-size-xx-small: (@font-size-medium * 0.55);
@font-weight-lighter: 300 ;
@font-weight-normal: 400 ;
@font-weight-bold: 600 ;
@color-text: #333 ;
@color-text-light: rgba(51,51,51,0.4) ;

@color-main: rgb(0, 110, 190) ;
@color-main-70: rgba(0, 110, 190, 0.7) ;
@color-main-50: rgba(0, 110, 190, 0.5) ;
@color-main-30: rgba(0, 110, 190, 0.3) ;
@color-main-10: rgba(0, 110, 190, 0.1) ;
@color-secondary: rgba(0, 110, 190, 0.1) ;

@color-gray: rgba(0, 0, 0, 1) ;
@color-gray-70: rgba(0, 0, 0, 0.7) ;
@color-gray-50: rgba(0, 0, 0, 0.5) ;
@color-gray-30: rgba(0, 0, 0, 0.3) ;
@color-gray-20: rgba(0, 0, 0, 0.2) ;
@color-gray-10: rgba(0, 0, 0, 0.1) ;
@color-gray-5: rgba(0, 0, 0, 0.05) ;
@color-green: #009600 ;
@color-red: #FF0000 ;	
@color-white: #FFF ;
@color-black: #000 ;

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `@screen-xs` as of v3.0.1
@screen-xs:                  480px;
//** Deprecated `@screen-xs-min` as of v3.2.0
@screen-xs-min:              @screen-xs;
//** Deprecated `@screen-phone` as of v3.0.1
@screen-phone:               @screen-xs-min;

// Small screen / tablet
//** Deprecated `@screen-sm` as of v3.0.1
@screen-sm:                  768px;
@screen-sm-min:              @screen-sm;
//** Deprecated `@screen-tablet` as of v3.0.1
@screen-tablet:              @screen-sm-min;

// Medium screen / desktop
//** Deprecated `@screen-md` as of v3.0.1
@screen-md:                  992px;
@screen-md-min:              @screen-md;
//** Deprecated `@screen-desktop` as of v3.0.1
@screen-desktop:             @screen-md-min;

// Large screen / wide desktop
//** Deprecated `@screen-lg` as of v3.0.1
@screen-lg:                  1200px;
@screen-lg-min:              @screen-lg;
//** Deprecated `@screen-lg-desktop` as of v3.0.1
@screen-lg-desktop:          @screen-lg-min;

// So media queries don't overlap when required, provide a maximum
@screen-xs-max:              (@screen-sm-min - 1);
@screen-sm-max:              (@screen-md-min - 1);
@screen-md-max:              (@screen-lg-min - 1);
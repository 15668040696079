.container-pictures {
    .block-view {
        .thumbnail {
            padding: 0;
            >.caption {
                margin: 0;
                padding: 0;
                >h3 {
                    
                }
                &.header {
                    >h3 {
						margin: 0;
						padding: 5px;
						text-align: center;
						font-size: small;
						background: #E7E7E7;
						color: #333;
						border-radius: 4px 4px 0px 0px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                }
                &.middle {
                    >h3 {
                        margin: 0;
                        padding: 5px;
                        text-align: right;
                        font-size: small;
                        background: #E7E7E7;
                        color: #333;
                    }
                }
                &.footer {
                    >h3 {
                        margin: 0;
                        padding: 10px;
                        text-align: center;
                        font-size: medium;
                        color: #333;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        font-weight: bold;
                    } 
					>.btn-group {
						.btn {
							border-radius: 0;
							border: none;
						}
					}
                }
            }
            >.picture {
                margin: 10px;
                padding: 0;
                height: 150px;
            }
            &:hover,
            &:focus {
                border-color: #F04F00;
                text-decoration: none;
            }
        }
    }
    .block-form {
        
    }
}

.thumbnail.thumbnail-photo {
    padding: 0;
    >.caption {
        margin: 0;
        padding: 0;
        >h3 {
            
        }
        &.header {
            >h3 {
            margin: 0;
            padding: 5px;
            text-align: center;
            font-size: small;
            background: #E7E7E7;
            color: #333;
            border-radius: 4px 4px 0px 0px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            }
        }
        &.middle {
            >h3 {
                margin: 0;
                padding: 5px;
                text-align: right;
                font-size: small;
                background: #E7E7E7;
                color: #333;
            }
        }
        &.footer {
            >h3 {
                margin: 0;
                padding: 10px;
                text-align: center;
                font-size: medium;
                color: #333;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                font-weight: bold;
            } 
			>.btn-group {
				.btn {
					border-radius: 0;
					border: none;
				}
			}
        }
    }
    >.picture {
        margin: 10px;
        padding: 0;
        height: 150px;
        &.grayed {
            -webkit-filter: grayscale(100%);
            -moz-filter: grayscale(100%);
            -o-filter: grayscale(100%);
            -ms-filter: grayscale(100%);
            filter: grayscale(100%);
        }
    }
    &:hover,
    &:focus {
        border-color: #F04F00;
        text-decoration: none;
    }
}
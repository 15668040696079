.dropdown-menu {
	border-radius: 0;
	> li {
		i {
			margin-right: 5px;
			padding-right: 10px;
		}	
		> a {
		    color: @color-text;
		    background: transparent;
			.transition();
			> i {
			    color: @color-text;
				margin-right: 5px;
				padding-right: 10px;
				.transition();
			}
			&.disabled {
		        color: @color-text-light;
			    pointer-events: none;
			}
			&:hover,
			&:focus {
				color: @color-main;
				background: @color-secondary;
				> i {
					color: @color-main;
				}	
			}
		}
	    &.disabled > a{
	        color: @color-text-light;
		    pointer-events: none;
			> i {
				color: @color-text-light;
			}	
		}
		&.dropdown-header{
            color: #ffffff;
            padding: 5px 20px;
            margin-top: -5px;
            margin-bottom: -10px;
            background: @color-main;
            a,
            b {
                color: #fff;
            }
		}
	}	
}
label {
    text-transform: uppercase;
    color: #555555;
    font-weight: @font-weight-bold;
    font-size: @font-size-small;
    width: 100%;
    position: relative;
    >.open-help-block {
        cursor: help;
        font-size: large;
        position: absolute;
        right: 1px;
        top: -2px;
    }
}

.form-group {
	margin-bottom: 10px;
	>.form-control {
    	font-size: @font-size-medium;
    	border-radius: 0 !important;
        &:focus {
            -webkit-box-shadow: 0 0 1px 1px @color-main;
            -moz-box-shadow: 0 0 1px 1px @color-main;
            box-shadow: 0 0 1px 1px @color-main;
        }
        &:hover {
            border: 1px solid @color-main;
        }
    	&.disabled,
    	&[disabled],
    	&[readonly] {
    		pointer-events: none;
            background-color: #FFD0D0;
    	}
	}
	>.help-block {
        opacity: 0;
        margin-top: 2px;
        margin-bottom: 0;
        color: #a94442;
        font-size: 70%;
        text-align: right;
        float: right;
        .transition() ;
        &.open {
            opacity: 1;
        }
	}
	&:hover {
        >label {
            color: @color-main;
        }
	}
}

.input-group{
    width: 100%;
	.input-group-addon,
	.input-group-addon > a {
        border-radius: 0;
	}
	>.input-group-btn {
	    width: 1% !important;
	}
	>.form-control{
    	border-radius: 0 !important;
        &:focus{
            -webkit-box-shadow: 0 0 1px 1px @color-main;
            -moz-box-shadow: 0 0 1px 1px @color-main;
            box-shadow: 0 0 1px 1px @color-main;
            -webkit-box-shadow: 0 0 1px 1px @color-main;
            box-shadow: 0 0 1px 1px @color-main;
        }
        &:hover{
            border: 1px solid @color-main;
        }
    	&.disabled,
    	&[disabled],
    	&[readonly] {
    		pointer-events: none;
            background-color: #FFD0D0;
    	}
	}
	&.input-group-sm {
	    .input-group-addon {
	        height: initial;
	    }
	    .form-control {
	        height: initial;
	    }
	    .btn {
	        height: initial;
	    }
	}
}

label.form {
    font-weight: 400;
    width: 100%;
    text-transform: uppercase;
    font-size: small;
    color:#656565;
    margin-bottom: 0px;
    background: rgba(204, 204, 204, 0.36);
    display: block;
    border-radius: 10px 10px 0 0;
    padding: 5px 0px 5px 10px;
}
	
&.mini{
	margin-bottom:0px;
	.form-control {
		font-size: @font-size-small;	
		height: 25px;
		padding: 3px 3px 3px 3px;
	}	
}

input[type="checkbox"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
    margin: 7px 0px 0px -25px;
}

select,
select.form-control,
textarea,
textarea.form-control,
input.form-control,
input[type=text],
input[type=password],
input[type=email],
input[type=number],
[type=text].form-control,
[type=password].form-control,
[type=email].form-control,
[type=tel].form-control,
[contenteditable].form-control{
    border-radius:0px;
}
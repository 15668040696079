.btn {
	border-radius: 0 !important;
	.transition();
    &.with-icon-stack {
        padding: 2px 6px !important;
    }
	&.btn-primary {
    	color: #fff;
    	background-color: @color-main;
    	border-color: @color-main;
		&:hover {
		    color: @color-main;
		    background-color: #fff;
		    border-color: #fff;
		}
	}
	&.btn-default {
		&:hover,
		&:focus,
		&:active,
		&.active {
		    color: @color-main;
		    background-color: @color-main-10;
		    border-color: #cbcbcb;
		    box-shadow: none;
		    >i {
				color: @color-main;
		    }
		}
	}
	&.disabled {
		pointer-events: none;
	}
}

.btn-group {
	>.btn {
		
	}
}
.modal {
    z-index: 4200;
    .modal-dialog {
        margin: 64px auto 0 auto;
    	.modal-content {
    		border: none;
    		.border-radius( 0px, 0px, 0px, 0px );
            -webkit-box-shadow: 0 5px 5px -2px #013252;
            -moz-box-shadow: 0 5px 5px -2px #013252;
            box-shadow: 0 5px 5px -2px #013252;
            -webkit-box-shadow: 0 5px 5px -2px #013252;
            box-shadow: 0 5px 5px -2px #013252;
    		.modal-header {
    			background: @color-main;
    			padding: 10px 10px 10px 10px;
    			button.close {
    				color: #FFF;
    				.opacity( 1 );
    				margin-top: 3px;
    			}
    			button.close:hover, 
    			button.close:focus {
    				color:@color-red;
    				.opacity( 1 );
    			}
    			.modal-title {
    				color: #fff;
    				font-weight: bold;
    				text-transform: uppercase;
    				font-size: large;
    				text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);
    			    text-align: center;
    			}
    		}
    		.modal-body {
    			max-height: 500px;
    			overflow: auto;
    			padding: 16px 16px 16px 16px;
    			.box-shadow( inset 0px 0px 50px 0px @color-gray-20 );
    			.page-header {
    				padding-bottom: 0;
    				margin: 15px 0 15px;
    				> h1, 
    				> h2, 
    				> h3, 
    				> h4 {
    					color: @color-main;
    					font-weight: 500;			
    				}
    			}
    		}
    		.modal-footer {
    			background: @color-main;
    			.border-radius( 0px, 0px, 0px, 0px );
    			.btn {
                    padding: 5px 10px;
                    font-size: 12px;
                    line-height: 1.5;
                    border-radius: 3px;  
    			}
    		}
    	}	
    }
}	
.modal-backdrop {
    z-index: 2100;
    &.in {
        -moz-opacity: 0.8;
        -khtml-opacity: 0.8;
        -webkit-opacity: 0.8;
        -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=80);
        opacity: 0.8;
        filter: alpha(opacity=80);
    }
}
.panel{
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
	border: none;
    border-radius: 0;
    margin-bottom: 0;
    background: none;
	> .panel-heading{
		color: @color-gray;
		font-weight: bold;
		text-transform: uppercase;	
		font-size: @font-size-large;
	    border-radius: 0;
		>i, 
		>.glyphicon{
			color: @color-main;
			margin-right: 5px;
			padding-right: 10px;
			border-right: 1px solid @color-main;						
		}
		.btn{
            padding: 5px 10px;
            font-size: 12px;
            line-height: 1.5;
            border-radius: 3px;
            float: right;
		}
        .badge {
            float: right;
            color: @color-main;
            background-color: #fff;
            min-width: 50px;
            padding: 8px 10px 8px 10px;
            border-radius: 25px;
            font-weight: bold;
            box-shadow: inset 0 0 8px 1px #BDBDBD;
        }
        >.panel-title {
			color: @color-gray;
			font-weight: bold;
			text-transform: uppercase;	
	        >.progress {
	        	min-width: 100px;
				width: 25%;
				margin: 0;
				height: 22px;
				border-radius: 0;
				background-color: #e5e5e5;
	        }
        }
        >h1.panel-title {
    		font-size: @font-size-x-large;
	        >.progress {
				height: 22px;
	        }
        }
        >h2.panel-title {
    		font-size: @font-size-large;
	        >.progress {
				height: 20px;
	        }
        }
        >h3.panel-title {
    		font-size: @font-size-medium;
	        >.progress {
				height: 16px;
	        }
        }
	}
	.panel-body{
	    background: none;
	    >h3 {
		    margin: 0 0 5px;
		    font-weight: bold;
		    color: #0071bd;
		    border-bottom: 1px solid;
		    padding: 0 0 5px;
	    }
	    >p {
	    	margin-left: 0 0 20px 20px;
	    }
	}
	.panel-footer{
		background: @color-main;
	}
}

.panel-group{
	.panel{
		> .panel-heading, 
		> .panel-heading:hover{
			> a{
				color: @color-gray;
				text-decoration:none;
				> i, 
				> .glyphicon{
					color: @color-main;
					margin-right: 5px;
					padding-right: 10px;
					border-right: 1px solid @color-main;						
				}
			}
			> a.collapsed, a:hover, a:focus{
				text-decoration:none;
			}
		}
		> .panel-heading:hover{
			.gradient( @color-gray-30, @color-gray-10, @color-white );
		}
	}
}